<template>
  <App
    title="跟进详情"
    left-arrow
    @click-left="onNavBarClickLeft"
  >
    <div class="full-container">
      <div class="info-header">
        <div>
          <Icon
            name="manager"
            size="40"
            color="#ccc"
          ></Icon>
        </div>
        <div class="detail">
          <div class="name">{{infoData.customerName}}</div>
          <div class="phone">{{infoData.customerPhone}}</div>
        </div>
      </div>
      <template>
        <CellGroup>
          <Cell
            v-if="infoData.followUpPeople"
            title="Follow-up Person"
            :value="infoData.followUpPeople"
          />
          <Cell
            v-if="infoData.type"
            title="Follow-up Method"
            :value="infoData.type"
          />
          <Cell
            v-if="infoData.followUpTime"
            title="Follow-up Time"
            :value="infoData.followUpTime"
          />
          <Collapse
            v-model="info"
            v-if="infoData.followUpInfo"
            accordion
          >
            <CollapseItem
              title="Follow-up Details"
              name="info"
            >
              <template>
                <div style="word-wrap:break-word">{{infoData.followUpInfo}}</div>
              </template>
            </CollapseItem>
          </Collapse>
          <Cell
            v-if="infoData.nextFollowUpTime"
            title="Next Follow-up Time"
            :value="infoData.nextFollowUpTime"
          />
          <template v-if="detail.type === 'LEADS'">
            <Cell
              v-if="infoData.statusTxt"
              title="Leads Status"
              :value="infoData.statusTxt"
            />
            <Cell
              v-if="infoData.score && detail.status ==='IN_TOUCHING'"
              title="Rating"
              :value="infoData.score"
            />
          </template>
          <!-- <template v-else>
            <Cell
              v-if="infoData.statusTxt"
              title="Opp状态"
              :value="infoData.statusTxt"
            />
            <Cell
              v-if="infoData.closeData"
              title="Close Date"
              :value="infoData.closeData"
            />
          </template>
          <template v-if="detail.status !=='IN_TOUCHING' && (detail.status !== 'CLOSE_LOST' || detail.status !== 'CLOSELOST')">
            <Cell
              v-if="detail.reason"
              :title="infoData.statusTxt + '原因'"
              :value="detail.reason"
            />
          </template>
          <template v-if="detail.status === 'CLOSE_LOST' || detail.status === 'CLOSELOST'">
            <Cell
              v-if="detail.closeLostReason"
              title="暂败原因"
            >
              <template v-for="(el, index) in closeReson">
                <span :key="index">{{el}}{{index === (closeReson.length-1) ? '' : ';'}}</span>
              </template>
            </Cell>
          </template> -->

          <!-- <template v-if="infoData.stiProfilePart">
            <Cell
              v-for="(item, index) in infoData.stiProfilePart"
              :key="index"
            >
              <template #title>
                {{item.name}}
              </template>
              <template v-for="(el, i) in item.trees">
                <span :key="i+1">{{el.name}}{{i === (item.trees.length-1) ? '' : ';'}}</span>
              </template>
            </Cell>
          </template> -->

        </CellGroup>
      </template>
    </div>
  </App>
</template>

<script>
import { CellGroup, Cell, Icon, Collapse, CollapseItem } from 'vant'
import dayjs from 'dayjs'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import './index.less';

export default {
  name: 'followUpInfo',
  components: { CellGroup, Cell, Icon, Collapse, CollapseItem },
  data () {
    return {
      infoData: {},
      detail: {},
      info: 'info',
      closeReson: []
    }
  },
  methods: {
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    init () {
      this.detail = {}
      this.infoData = {}
      this.detail = this.$route.query.e
      const cpList = []
      const sti = JSON.parse(this.detail.stiProfilePart)
      if (sti && Array.isArray(sti)) {
        sti.forEach(el => {
          if (el['trees'].length > 0) {
            cpList.push(el)
          }
        })
      }
      this.infoData = {
        customerName: this.detail.name,
        customerPhone: this.detail.phone,
        followUpPeople: this.detail.counselor,
        type: this.detail.fromText,
        followUpTime: this.detail.updatetime ? dayjs(this.detail.updatetime).format('YYYY/MM/DD HH:mm:ss') : '',
        followUpInfo: this.detail.followupMessage,
        nextFollowUpTime: this.detail.followupTime ? dayjs(this.detail.followupTime).format('YYYY/MM/DD') : '',
        statusTxt: this.detail.statusTxt,
        stiProfilePart: cpList
      }
      if (this.detail.type === 'LEADS') {
        this.infoData['score'] = this.detail.stiScore
      } else {
        this.infoData['closeData'] = this.detail.closedate ? dayjs(this.detail.closedate).format('YYYY/MM/DD') : ''
      }
      // 暂败原因做转化
      if (this.detail.closeLostReason) {
        this.closeReson = []
        this.$storage.get('baseData')['close_lost_reason'].forEach(el => {
          JSON.parse(this.detail.closeLostReason).forEach(item => {
            if (+item === +el.value) {
              this.closeReson.push(el.key)
            }
          })
        })
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
